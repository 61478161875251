<template>
  <div class="border-top-info border-3 box-shadow-3 rounded-bottom">
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      @reload="$refs['refProductActionsList'].refresh()"
    >
      <!-- Table -->
      <b-table
        small
        slot="table"
        no-provider-filtering
        :api-url="'/logistics/products-inventory/get-product-actions'"
        ref="refProductActionsList"
        :items="myProvider"
        :fields="arrayColumns"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- Column IMAGE -->
        <template #cell(url_image)="data">
          <div class="image-upload">
            <input type="file" id="file_input" hidden />
            <div class="form-group">
              <figure>
                <img
                  v-if="data.item.url_image"
                  width="80"
                  height="80"
                  :src="data.item.url_image"
                />
                <img
                  v-else
                  width="80"
                  height="80"
                  :src="assetsImg + '/images/inventory.jpg'"
                />
              </figure>
            </div>
          </div>
        </template>

        <!-- Column PRICE -->
        <!-- <template #cell(cost)="data">
          {{ data.item.currency }} {{ data.item.cost }}
        </template> -->

        <!-- Column Description -->

        <!-- Column CREATED BY -->
        <template #cell(created_at)="data">
          <div
            class="d-flex flex-column justify-content-start align-items-start"
          >
            <span>{{data.item.first_name}} {{data.item.last_name}}</span>
            <span>{{ data.item.created_by }}</span>
            <div>{{ data.item.created_at | myGlobalDay }}</div>
          </div>
        </template>

        <!-- Columns ACTIONS -->
      </b-table>
    </filter-slot>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import vSelect from "vue-select";

import ProductInventoryService from "../services/product_inventory.service";
// Import Filter
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";

import fields from "../data/fields.productactions.data";
import filters from "../data/filter.inventorygrid.data";

// MODAL
import ModalTrackingProduct from "../modals/TrackingProduct.vue";
import ModalStockProduct from "../modals/StockProduct.vue";

export default {
  props: {
    //
  },
  components: {
    vSelect,
    FilterSlot,
    "modal-tracking-product": ModalTrackingProduct,
    "modal-stock-product": ModalStockProduct,
  },
  data: function () {
    return {
      sortBy: "created_at",
      sortDesc: true,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Product...",
        model: "",
      },
      arrayColumns: fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      startPage: null,
      toPage: null,
      modalTracking: false,
      sortBy: "created_at",
      filter: filters,
      isBusy: false,
      productId: "",
      modalStock: false,
      status: "",
      product: "",
      current_stock: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  mounted() {
    //
  },
  methods: {
    async myProvider(ctx) {
      try {
        const data = await amgApi.post(
          `${ctx.apiUrl}?page=${ctx.currentPage}`,
          {
            perpage: ctx.perPage,
            from: this.filter[0].model,
            to: this.filter[1].model,
            name_text: this.filterPrincipal.model,
            status_action: this.$route.meta.tab,
            order: ctx.sortBy == "" ? "created_at" : ctx.sortBy,
            orderby: ctx.sortDesc == 1 ? "desc" : "asc",
            category: this.filter[2].model,
          }
        );
        const items = data.data.data;
        this.startPage = data.data.from;
        this.currentPage = data.data.current_page;
        this.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        return items || [];
      } catch (error) {
        this.showErrorSwal(error);
        return [];
      }
    },
    getSelectCategory: async function () {
      try {
        const response = await ProductInventoryService.getSelectCategory({});
        if (response.status == 200) {
          this.filter[2].options = response.data;
        }
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
  },
};
</script>
