export default[
    {
      key: "url_image",
      label: "Image",
      class: "text-left",
      sortable: false,
    },
    {
      key: "code",
      label: "Code",
      class: "text-left",
      sortable: false,
    },
    {
      key: "category_name",
      label: "Category",
      class: "text-left",
      sortable: false,
    },
    {
      key: "product",
      label: "Product",
      class: "text-left",
      sortable: false,
    },
    {
        key: "previous_value",
        label: "Previous Value",
        class: "text-left",
        sortable: false,
    },
    {
      key: "cant",
      label: "Quantity",
      class: "text-left",
      sortable: false,
  },
    {
        key: "new_value",
        label: "New Value",
        class: "text-left",
        sortable: false,
    },
    {
      key: "created_at",
      label: "Created By",
      class: "text-left",
      sortable: true,
    }
    
]